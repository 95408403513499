import request from "@/core/services/axios";
import qs from "qs";

// 新增考评
export function addEvaluation(data) {
  return request({
    url: "/qa/evaluation",
    method: "post",
    data: data,
  });
}

// 通过id查询
export function evaluationGetbyid(query) {
  return request({
    url: "/qa/evaluation/getbyid",
    method: "get",
    params: query,
  });
}

// 引用考评表
export function evaluationquestionQuote(query) {
  return request({
    url: "/qa/evaluationquestion/quote",
    method: "get",
    params: query,
  });
}

// 测评学生信息汇总
export function studentsinfo(query) {
  return request({
    url: "/qa/evaluationstudent/studentsinfo",
    method: "get",
    params: query,
  });
}

// 获取考评分配列表详情
export function distribution(query) {
  return request({
    url: "/qa/evaluationquestion/get/distribution",
    method: "get",
    params: query,
  });
}

// 开启-关闭考评
export function evaluationSwitch(query) {
  return request({
    url: "/qa/evaluation/switch",
    method: "get",
    params: query,
  });
}

// 教师-获取当前老师角色列表
export function evaluationteacherGetRoles(query) {
  return request({
    url: "/qa/evaluationteacher/get/roles",
    method: "get",
    params: query,
  });
}

// 教师-获取可批阅的班级列表
export function getClassnums(query) {
  return request({
    url: "/qa/evaluationteacher/get/classnums",
    method: "get",
    params: query,
  });
}

// 测回发布
export function evaluationRevoke(query) {
  return request({
    url: "/qa/evaluation/revoke",
    method: "get",
    params: query,
  });
}

// 根据条件查询测评项目列表
export function evaluationList(data) {
  return request({
    url: "/qa/evaluation/list",
    method: "post",
    data: data,
  });
}

// 教师-获取学生列表
export function getStudents(data) {
  return request({
    url: "/qa/evaluationteacher/get/students",
    method: "post",
    data: data,
  });
}

// 查询统计情况
export function evaluationstudentstat(data, query) {
  let dataObj = qs.stringify(query);
  return request({
    url: "/qa/evaluationstudentstat/get/stat/?" + dataObj,
    method: "post",
    data: data,
  });
}

// 导出统计
export function evaluationstudentstatExport(data) {
  return request({
    url: "/qa/evaluationstudentstat/export",
    method: "post",
    data: data,
  });
}

// 教师-获取题目列表
export function getQuestions(query) {
  return request({
    url: "/qa/evaluationteacher/get/questions",
    method: "get",
    params: query,
  });
}

// 教师-回显学生打分结果
export function studentScore(query) {
  return request({
    url: "/qa/evaluationteacher/get/student/score",
    method: "get",
    params: query,
  });
}

// 教师-获取素质测评列表
export function evaluationteacherList(query) {
  return request({
    url: "/qa/evaluationteacher/get/list",
    method: "get",
    params: query,
  });
}

// 开始统计
export function statisticsevaluation(query) {
  return request({
    url: "/qa/evaluationstudentstat/statistics/evaluation",
    method: "get",
    params: query,
  });
}

// 发布成绩
export function evaluationPublish(query) {
  return request({
    url: "/qa/evaluation/publish",
    method: "get",
    params: query,
  });
}

// 考评人分配
export function evaluationteacherDistribution(data) {
  return request({
    url: "/qa/evaluationteacher/distribution",
    method: "post",
    data: data,
  });
}

// 教师-提交打分
export function evaluationteacherSubmit(data) {
  return request({
    url: "/qa/evaluationteacher/submit",
    method: "post",
    data: data,
  });
}

// 进度跟踪
export function progressTracking(data) {
  let dataObj = qs.stringify(data);
  return request({
    url: "/qa/evaluationteacher/progress/tracking?" + dataObj,
    method: "post",
    // data: data,
  });
}

// 修改考评
export function putEvaluation(data) {
  return request({
    url: "/qa/evaluation",
    method: "put",
    data: data,
  });
}

// 分页查询 测评项目
export function evaluationPage(data, query) {
  let dataObj = qs.stringify(query);
  return request({
    url: "/qa/evaluation/page?" + dataObj,
    method: "post",
    data: data,
  });
}

// 删除 测评项目
export function deleteEvaluation(id) {
  return request({
    url: "/qa/evaluation/" + id,
    method: "delete",
  });
}

// 删除 学生
export function deleteEvaluationstudent(id) {
  return request({
    url: "/qa/evaluationstudent/" + id,
    method: "delete",
  });
}

// 通过id删除小题
export function deleteEvaluationquestion(id) {
  return request({
    url: "/qa/evaluationquestion/" + id,
    method: "delete",
  });
}

// 添加学生
export function addUseclass(data) {
  return request({
    url: "/qa/evaluationstudent/add/useclass",
    method: "post",
    data: data,
  });
}

// 获取测评试题列表
export function getQuestion(data) {
  let dataObj = qs.stringify(data);
  return request({
    url: "/qa/evaluationquestion/get/question?" + dataObj,
    method: "post",
    // data: data,
  });
}

// 通过id修改是否参与统计
export function putEvaluationstudent(data) {
  return request({
    url: "/qa/evaluationstudent",
    method: "put",
    data: data,
  });
}

// 分页查询学生列表
export function evaluationstudentGetPage(data, query) {
  let dataObj = qs.stringify(query);
  return request({
    url: "/qa/evaluationstudent/get/page?" + dataObj,
    method: "post",
    data: data,
  });
}

// 进度跟踪-总量
export function trackingAll(query) {
  let dataObj = qs.stringify(query);
  return request({
    url: "/qa/evaluationteacher/progress/tracking/all?" + dataObj,
    method: "post",
    // data: data,
  });
}
