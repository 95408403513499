<template>
  <div class="evaluate-header">
    <div class="title" @click="goBack">
      <i class="el-icon-arrow-left"></i>
      {{ name || "" }}
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    name: {
      type: String,
      default: "",
    },
    urlName: {
      type: String,
      default: "",
    },
    url: {
      type: String,
      default: "",
    },
    query: {
      type: Object,
      default(query) {
        return query;
      },
    },
  },
  data() {
    return {};
  },
  created() {
    // console.log(this.query);
  },
  methods: {
    goBack() {
      if (this.url) {
        this.$router.push({
          name: this.url,
          query: this.query,
        });
      } else {
        this.$router.go(-1);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.evaluate-header {
  background-color: #ffffff;
  padding: 24px 24px 0;
  display: flex;
  justify-content: space-between;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  .el-button {
    margin-bottom: 24px;
  }

  .title {
    font-size: 16px;
    font-weight: bold;
    color: #080a09;
    margin-bottom: 24px;
    cursor: pointer;
    // width: 100%;
    .el-icon-arrow-left {
    }
  }
}
</style>
