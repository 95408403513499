<template>
  <div class="evaluate-distribute">
    <EvaluateHeader :name="evaluation.name + ' / 考评分配'"> </EvaluateHeader>
    <div class="tab-box">
      <div class="tab-btn-box">
        <el-button type="primary" @click="showAllocation(0)"
          >批量分配</el-button
        >
      </div>
      <el-table
        ref="multipleTable"
        :data="distributionList"
        border
        style="width: 100%"
        tooltip-effect="dark"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" align="center">
        </el-table-column>
        <!-- <el-table-column label="序号" type="index" width="55">
        </el-table-column> -->
        <el-table-column
          width="55"
          label="序号"
          prop="evaluationQuestionOrder"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="name"
          width="187"
          label="题目类型"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.type ? "评分多选" : "评分单选" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="questionDirection"
          width="187"
          label="考评项目"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="evaluationRange"
          width="187"
          label="考评范围"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.evaluationRange | setName(evaluationRange) }}
          </template>
        </el-table-column>
        <el-table-column prop="name" label="考评人" header-align="center">
          <template slot-scope="scope">
            <template v-if="scope.row.teacherRole != 6">
              <div v-for="(v, i) in classList" :key="i" class="group-item">
                <div :key="i + 'group-item-title'" class="group-item-title">
                  <span
                    >{{ v
                    }}<template v-if="v[v.length - 1] != '班'"
                      >班</template
                    ></span
                  >
                </div>
                <div class="group-item-list">
                  <template v-for="(item, index) in scope.row.evaluatorVOList">
                    <span v-if="v == item.classNum" :key="index"
                      ><template v-if="item.subjectId"
                        >{{
                          item.subjectId | setName(filterSubject)
                        }}：</template
                      >{{ item.teacherName + `（${item.number}）` }}</span
                    >
                  </template>
                </div>
              </div>
            </template>
            <template v-else>
              <div
                v-for="(v, i) in scope.row.titleArr"
                :key="i + 'titleArr'"
                class="group-item"
              >
                <div :key="i + 'group-item-title'" class="group-item-title">
                  <span>{{ v }}</span>
                </div>
                <div class="group-item-list">
                  <template v-for="(item, index) in scope.row.evaluatorVOList">
                    <span v-if="v == item.title" :key="index"
                      >宿管：{{ item.teacherName + `（${item.number}）` }}</span
                    >
                  </template>
                </div>
              </div>
            </template>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="操作" width="106" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="showAllocation(1, scope.row)"
              >分配</el-button
            >
          </template>

          <!-- <el-button type="text" class="err">删除</el-button> -->
        </el-table-column>
      </el-table>
      <!-- <el-pagination
        :current-page="pageIndex"
        :page-sizes="[10, 20, 50, 100, 200, 500, 1000]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination> -->
    </div>

    <el-dialog title="分配" :visible.sync="dialogVisible" width="450px">
      <div class="mode-item">
        考评范围：<el-radio-group v-model="evaluationType">
          <el-radio
            v-for="(v, i) in evaluationRange"
            :key="i"
            :label="v.value"
            :disabled="getdis1(v.value)"
            >{{ v.label }}</el-radio
          >
        </el-radio-group>
      </div>
      <div class="mode-item">
        考评人角色：<el-radio-group v-model="teacherRole">
          <el-radio
            v-for="(v, i) in teacherRoleList"
            :key="i"
            :label="v.value"
            :disabled="getdis(v.value)"
            >{{ v.label }}</el-radio
          >
        </el-radio-group>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="submit()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import EvaluateHeader from "./component/evaluate-header";
import {
  evaluationGetbyid,
  distribution,
  evaluationteacherDistribution,
  // studentsinfo,
  // deleteEvaluationstudent,
} from "@/core/api/evaluate/evaluate";
import { filterSubject } from "@/core/util/util";
import { evaluationRange, teacherRoleList } from "@/core/util/constdata";
export default {
  name: "EvaluateDistribute",
  components: { EvaluateHeader },
  data() {
    return {
      dialogVisible: false,
      btnLoading: false,
      evaluationType: "",
      teacherRole: "",
      filterSubject: filterSubject(),
      evaluationRange: evaluationRange,
      teacherRoleList: teacherRoleList,
      pageIndex: 1,
      pageSize: 10,
      total: 0,
      searchItems: {
        classNum: "",
        name: "",
      },
      studentList: [],
      distributionList: [],
      classList: [],
      evaluation: {
        name: "",
      },
    };
  },
  created() {
    this.evaluationGetbyid();
    this.distribution();
    // this.keyUp();
  },
  methods: {
    getdis(val) {
      let type = false;
      if (
        val == 6 &&
        (this.evaluationType === 0 || this.evaluationType === 2)
      ) {
        type = true;
      }
      if (val != 6 && this.evaluationType === 1) {
        type = true;
      }
      return type;
    },
    getdis1(val) {
      let type = false;
      if ((val == 0 || val == 2) && this.teacherRole === 6) {
        type = true;
      }
      return type;
    },
    keyUp() {
      let that = this;
      document.onkeydown = function (event) {
        let e = event || window.event;
        let keycode = e.keyCode;
        if (keycode == 16) {
          that.ifDwonShift = true;
        }
      };
      document.onkeyup = function (event) {
        let e = event || window.event;
        let keycode = e.keyCode;
        if (keycode == 16) {
          that.ifDwonShift = false;
        }
      };
    },
    showAllocation(type, item) {
      this.evaluationType = "";
      this.teacherRole = "";
      if (!type) {
        // console.log();
        if (!this.multipleSelection || this.multipleSelection.length == 0) {
          this.$message({
            showClose: true,
            type: "warning",
            message: "请选择需要批量设置的试题！",
          });
          return;
        }
        this.indexQuestion = {};
        this.dialogVisible = true;
      } else {
        this.indexQuestion = item;
        this.multipleSelection = [];
        this.multipleSelection.push(item);
        this.dialogVisible = true;
      }
    },
    evaluationteacherDistribution(array) {
      this.btnLoading = true;
      evaluationteacherDistribution(array)
        .then(() => {
          this.$message({
            showClose: true,
            type: "success",
            message: "设置成功！",
          });
          this.dialogVisible = false;
          this.btnLoading = false;
          if (this.indexQuestion && this.indexQuestion.id) {
            this.indexQuestion = {};
            this.multipleSelection = [];
          }
          this.distribution();
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    submit() {
      if (this.evaluationType === "") {
        this.$message({
          showClose: true,
          type: "warning",
          message: "请选择考评范围！",
        });
        return;
      }
      if (!this.teacherRole) {
        this.$message({
          showClose: true,
          type: "warning",
          message: "请选择考评人角色！",
        });
        return;
      }
      let array = [];
      // if(this.)
      this.multipleSelection.map((index) => {
        array.push({
          evaluationQuestionId: index.id,
          evaluationRange: this.evaluationType,
          id: this.$route.query.id,
          teacherRole: this.teacherRole,
        });
      });
      this.evaluationteacherDistribution(array);
      // console.log(array);
      // console.log(this.multipleSelection);
    },
    distribution() {
      let data = { id: this.$route.query.id };
      distribution(data).then((res) => {
        res.data.data.map((item) => {
          if (item.evaluatorVOList && item.evaluatorVOList.length > 0) {
            item.teacherRole = item.evaluatorVOList[0].teacherRole;
          }
          // 如果是宿管 情况  拼接楼层信息
          if (item.teacherRole == 6) {
            item.titleArr = [];
            item.evaluatorVOList.map((index) => {
              index.title = index.dormitory + "楼" + index.floor + "层";
              if (item.titleArr.indexOf(index.title) == -1) {
                item.titleArr.push(index.title);
              }
            });
          }
        });
        // console.log(res.data.data);
        this.distributionList = res.data.data;
        // this.total = res.data.data.total;
      });
    },
    // 获取单个考评详情
    evaluationGetbyid() {
      let data = { id: this.$route.query.id };
      evaluationGetbyid(data).then((res) => {
        this.evaluation = res.data.data;
        this.classList = this.evaluation.classNums.split(",");
      });
    },
    handleSelectionChange(val) {
      //   console.log(val);
      //   // console.log(index);
      this.multipleSelection = val;
      //   if (val && val.length == 2 && this.ifDwonShift) {
      //     this.distributionList.map((item, index) => {
      //       console.log(item);
      //       console.log(index);
      //     });
      //   }
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.getDataList();
    },
    handleCurrentChange(val) {
      this.pageIndex = val;
      this.getDataList();
      // console.log(`当前页: ${val}`);
    },
    handleClick() {},
  },
};
</script>
<style scoped lang="scss">
.evaluate-distribute {
  .mode-item {
    display: flex;
    align-items: flex-start;
    align-items: center;
    margin-bottom: 18px;
    .el-radio {
      margin-right: 24px;
      margin-bottom: 0;
      line-height: 21px;
      &:last-child {
        margin-right: 0;
      }
    }
    .el-checkbox {
      margin-right: 24px;
      margin-bottom: 0;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .group-item {
    display: flex;
    // padding-left: 68px;
    position: relative;

    min-height: 23px;
    margin-bottom: 11px;
    &:last-child {
      margin-bottom: 0;
    }
    .group-item-list {
      display: flex;
      flex-wrap: wrap;
    }
    .group-item-title {
      min-width: 68px;
      // position: absolute;
      left: 0;
      span {
        border-radius: 4px;
        line-height: 14px;
        border: 1px solid #2474ed;
        color: #2474ed;
        padding: 0px 7px;
      }
    }
    > span {
      margin-right: 18px;
      color: #606266;
    }
  }
  .el-pagination {
    display: flex;
    justify-content: flex-end;
  }
  .tab-box {
    margin: 24px 0;
    background-color: #ffffff;
    padding: 24px;
    .tab-btn-box {
      text-align: right;
      margin-bottom: 24px;
    }
    .err {
      color: red;
    }
    .tab-msg {
      padding: 10px;
      background: #f2f8ff;
      border: 1px dashed #2474ed;
      color: #5f5f68;
      margin-bottom: 24px;
    }
  }
  .search-box {
    display: flex;
    justify-content: space-between;
    background-color: #ffffff;
    padding: 18px 24px;
    margin: 24px 0;
    .el-input {
      width: 140px;
    }
    .el-select {
      width: 140px;
      margin-right: 18px;
    }
  }
}
</style>
